import React from "react";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";

const Navbar = ({ TotalProperty, TotaPaidProperty }) => {
  return (
    <div>
      <nav
        className="navbar bg-body-tertiary fixed-top"
        style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2" }}
      >
        <div className="container-fluid">
          <a href="/" className="navbar-brand">
            <img src="/odirs-logo.png" width="120" height="42" alt="" />
          </a>
          <div
            style={{ fontSize: "20px", fontWeight: "700", color: "#595757" }}
          >
            Total Property :{" "}
            <span style={{ color: "#f64d0f", paddingLeft: "8px" }}>
              {TotalProperty}
            </span>
          </div>
          <div
            style={{ fontSize: "20px", fontWeight: "700", color: "#595757" }}
          >
            Total Paid Property:{" "}
            <span style={{ color: "#f64d0f", paddingLeft: "8px" }}>
              {TotaPaidProperty}
            </span>
          </div>

          <a
            className="btn btn-outline-success"
            data-bs-toggle="offcanvas"
            href="#offcanvasExample"
            role="button"
            aria-controls="offcanvasExample"
          >
            Enable Side Navigation
          </a>
        </div>
      </nav>

      <Sidebar />
    </div>
  );
};

export default Navbar;
