import "../Styles.css";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";
import Navbar from "../components/Navbar";

const GetLocation = () => {
  const [locations, setLocations] = useState([]);
  const [paidData, setPaidData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [locationCount, setLocationCount] = useState(0);
  const [paidCount, setPaidCount] = useState(0);

  const param = useParams();
  const stationCode = param.tax_station_code;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [locationResponse, paidDataResponse] = await Promise.all([
          axios.get(`https://odirslucbilling.ng/fetch_data.php`, {
            params: {
              staCode: stationCode,
            },
          }),
          axios.get("https://odirslucbilling.ng/fetch_paid_data.php"),
        ]);

        const locations = locationResponse.data;
        const paidData = new Set(paidDataResponse.data);

        const updatedLocations = locations.map((location) => ({
          ...location,
          isPaid: paidData.has(location.property_id),
        }));

        setLocations(updatedLocations);
        setLocationCount(locations.length);
        setPaidCount(
          updatedLocations.filter((location) => location.isPaid).length
        );
        setLoading(false);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, [stationCode]);

  const customIconRed = new Icon({
    iconUrl: "/map-pin.png",
    iconSize: [25, 25],
  });

  const customIconGreen = new Icon({
    iconUrl: "/green-pin.png",
    iconSize: [25, 25],
  });

  const loadingStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "100px",
  };

  return (
    <>
      <Navbar TotalProperty={locationCount} TotaPaidProperty={paidCount} />

      <MapContainer center={[6.9148682, 5.1478144]} zoom={9}>
        <TileLayer
          attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
          url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {locations.map((location) => (
          <Marker
            key={location.property_id}
            position={[location.latitude, location.longitude]}
            icon={location.isPaid ? customIconGreen : customIconRed}
          >
            <Popup>
              <img src={location.photo} width="200" height="100" alt="photo" />{" "}
              <br />
              <b>Staff Code</b> : {location.staff_code} <br /> <b>Street</b> :{" "}
              {location.street} {location.area} {location.city}
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </>
  );
};

export default GetLocation;
