import React from "react";
import { Link } from "react-router-dom";
const Sidebar = () => {
  return (
    <div>
      <div
        className="offcanvas offcanvas-start"
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <a href="/" className="offcanvas-title" id="offcanvasExampleLabel">
            <img src="/odirs-logo.png" width="130" height="45" alt="" />
          </a>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div
            style={{
              fontFamily: "system-ui",
              fontStyle: "oblique",
              color: "#198754",
              fontWeight: "700",
            }}
          >
            Below is a list of Tax Stations, you can click on any of the list
            item to view corresponding locations
          </div>
          <hr />
          <div className="list-group list-group-flush mt-4">
            <Link
              to="/getlocation/akoko-north-east/0101"
              className="list-group-item list-group-item-action fw-medium"
            >
              Akoko North East LGA (Ikare)
            </Link>

            <Link
              to="/getlocation/akoko-south-west/0064"
              className="list-group-item list-group-item-action fw-medium"
            >
              Akoko South West LGA (Oka)
            </Link>

            <Link
              to="/getlocation/akoko-north-west/0082"
              className="list-group-item list-group-item-action fw-medium"
            >
              Akoko North West (OkeAgbe)
            </Link>

            <Link
              to="/getlocation/akoko-south-east/0173"
              className="list-group-item list-group-item-action fw-medium"
            >
              Akoko South East LGA (Isua)
            </Link>

            <Link
              to="/getlocation/akure-north/0166"
              className="list-group-item list-group-item-action fw-medium"
            >
              Akure North LGA (Oba-Ile)
            </Link>

            <Link
              to="/getlocation/akure-south/0097"
              className="list-group-item list-group-item-action fw-medium"
            >
              Akure South (Ijoka/Oshinle)
            </Link>

            <Link
              to="/getlocation/akure-south-1/00910"
              className="list-group-item list-group-item-action fw-medium"
            >
              Akure South LGA (Express)
            </Link>

            <Link
              to="/getlocation/akure-south-2/00911"
              className="list-group-item list-group-item-action fw-medium"
            >
              Akure South LGA (HQ)
            </Link>

            <Link
              to="/getlocation/akure-south-3/00912"
              className="list-group-item list-group-item-action fw-medium"
            >
              Akure South (Oke-Aro/Isikan)
            </Link>

            <Link
              to="/getlocation/ese-odo/01213"
              className="list-group-item list-group-item-action fw-medium"
            >
              Ese Odo LGA (Igbekebo)
            </Link>

            <Link
              to="/getlocation/ifedore/00115"
              className="list-group-item list-group-item-action fw-medium"
            >
              Ifedore LGA (Igbaraoke)
            </Link>

            <Link
              to="/getlocation/ilaje/00316"
              className="list-group-item list-group-item-action fw-medium"
            >
              Ilaje LGA (Igbokoda)
            </Link>

            <Link
              to="/getlocation/ile-oluji/okeigbo/01117"
              className="list-group-item list-group-item-action fw-medium"
            >
              Ile-Oluji/Okeigbo LGA (IleOluji)
            </Link>

            <Link
              to="/getlocation/irele/01418"
              className="list-group-item list-group-item-action fw-medium"
            >
              Irele LGA (Ode-Irele)
            </Link>

            <Link
              to="/getlocation/idanre/01514"
              className="list-group-item list-group-item-action fw-medium"
            >
              Idanre LGA (Idanre)
            </Link>

            <Link
              to="/getlocation/odigbo-(ore-lagos)/00419"
              className="list-group-item list-group-item-action fw-medium"
            >
              Odigbo LGA (Ore-Lagos)
            </Link>

            <Link
              to="/getlocation/odigbo(ore-benin)/00420"
              className="list-group-item list-group-item-action fw-medium"
            >
              Odigbo LGA (Ore-Benin)
            </Link>

            <Link
              to="/getlocation/okitipupa/00521"
              className="list-group-item list-group-item-action fw-medium"
            >
              Okitipupa LGA (Okitipupa)
            </Link>

            <Link
              to="/getlocation/okitipupa-1/01822"
              className="list-group-item list-group-item-action fw-medium"
            >
              Ondo East LGA (Bolorunduro)
            </Link>

            <Link
              to="/getlocation/ondo-west/00223"
              className="list-group-item list-group-item-action fw-medium"
            >
              Ondo West LGA (Barracks Rd)
            </Link>
            <Link
              to="/getlocation/ondo-west-1/00224"
              className="list-group-item list-group-item-action fw-medium"
            >
              Ondo West LGA (Ademulegun)
            </Link>

            <Link
              to="/getlocation/ose/01325"
              className="list-group-item list-group-item-action fw-medium"
            >
              Ose LGA (Ifon)
            </Link>

            <Link
              to="/getlocation/owo/00726"
              className="list-group-item list-group-item-action fw-medium"
            >
              Owo LGA (Owo)
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
