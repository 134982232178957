import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import GetLocation from "./pages/GetLocation";
import Starter from "./pages/Starter";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<GetLocation />} />
        <Route
          path="/getlocation/akoko-north-east/:tax_station_code"
          element={<GetLocation />}
        />
        <Route
          path="/getlocation/akoko-south-west/:tax_station_code"
          element={<GetLocation />}
        />
        <Route
          path="/getlocation/akoko-north-west/:tax_station_code"
          element={<GetLocation />}
        />
        <Route
          path="/getlocation/akoko-south-east/:tax_station_code"
          element={<GetLocation />}
        />
        <Route
          path="/getlocation/akure-north/:tax_station_code"
          element={<GetLocation />}
        />
        <Route
          path="/getlocation/akure-south/:tax_station_code"
          element={<GetLocation />}
        />
        <Route
          path="/getlocation/akure-south-1/:tax_station_code"
          element={<GetLocation />}
        />
        <Route
          path="/getlocation/akure-south-2/:tax_station_code"
          element={<GetLocation />}
        />
        <Route
          path="/getlocation/akure-south-3/:tax_station_code"
          element={<GetLocation />}
        />
        <Route
          path="/getlocation/ese-odo/:tax_station_code"
          element={<GetLocation />}
        />
        <Route
          path="/getlocation/ifedore/:tax_station_code"
          element={<GetLocation />}
        />
        <Route
          path="/getlocation/ilaje/:tax_station_code"
          element={<GetLocation />}
        />
        <Route
          path="/getlocation/ile-oluji/okeigbo/:tax_station_code"
          element={<GetLocation />}
        />
        <Route
          path="/getlocation/irele/:tax_station_code"
          element={<GetLocation />}
        />
        <Route
          path="/getlocation/idanre/:tax_station_code"
          element={<GetLocation />}
        />
        <Route
          path="/getlocation/odigbo-(ore-lagos)/:tax_station_code"
          element={<GetLocation />}
        />
        <Route
          path="/getlocation/odigbo(ore-benin)/:tax_station_code"
          element={<GetLocation />}
        />
        <Route
          path="/getlocation/okitipupa/:tax_station_code"
          element={<GetLocation />}
        />
        <Route
          path="/getlocation/okitipupa-1/:tax_station_code"
          element={<GetLocation />}
        />
        <Route
          path="/getlocation/ondo-west/:tax_station_code"
          element={<GetLocation />}
        />
        <Route
          path="/getlocation/ondo-west-1/:tax_station_code"
          element={<GetLocation />}
        />
        <Route
          path="/getlocation/ose/:tax_station_code"
          element={<GetLocation />}
        />
        <Route
          path="/getlocation/owo/:tax_station_code"
          element={<GetLocation />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
